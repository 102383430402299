import React from 'react';
import {Link} from 'gatsby';

/* Title intro Section */
/**
 *
 * @param {boolean} isPageHeader uses h1 tag when active.
 * @returns
 */
const SectionIntro = ({
  color = 'blue',
  hero = false,
  title,
  description,
  isPageHeader = false,
  logo = false,
  showBtn = false,
}) => {
  const titleClassName = `leading-11 md:leading-[60px] font-bold text-3xl md:text-5xl m-auto text-center text-transparent bg-trustle-gradient bg-clip-text mb-4`;
  const hoverPrimary =
    'bg-gradient-to-r from-[#0091FF] via-[#005799] to-[#002761] hover:from-[#4185F4] hover:via-[#00315E] hover:to-[#00315E]';
  return (
    <>
      {hero && <p className={`text-center text-xl text-${color}`}>{hero}</p>}
      {logo && <img className="m-3 mr-0" src={logo} alt="trustle" width={140} />}
      {isPageHeader ? <h1 className={titleClassName}>{title}</h1> : <div className={titleClassName}>{title}</div>}
      <p
        className={`md:text-2xl text-xl max-w-4xl m-auto text-center mb-8 ${
          showBtn ? 'md:mb-8' : 'md:mb-16'
        } text-${color}`}
        dangerouslySetInnerHTML={{__html: description}}
      ></p>
      {showBtn && (
        <div className="flex flex-col pb-8 md:flex-row md:mx-auto">
          <a href="https://trustle.trustle.io/mkt/signup" target="_blank" rel="noreferrer">
            <div
              className={`${hoverPrimary} transition duration-300 ease-in-out active:bg-[#00549F] text-white text-center rounded-3xl drop-shadow-lg font-bold md:min-w-max lg:px-12 py-2 w-full lg:text-lg z-10 px-2 md:py1 md:max-w-[250px] mb-4 md:mb-0`}
            >
              Try Trustle Today!
            </div>
          </a>
          <Link to="/product/integrations">
            <div className="border border-white hover:bg-white hover:text-[#0067C4] transition duration-25 ease-in-out active:bg-white text-white text-center rounded-3xl drop-shadow-lg font-medium md:min-w-max lg:px-12 py-2 w-full lg:text-lg z-10 px-2 mb-1 md:py1 md:max-w-[250px] ml-0 md:ml-4 ">
              See all Integrations
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default SectionIntro;
