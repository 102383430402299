import React from 'react';
import russelThinking from '../images/russel/russel-thinking.svg';
import {Link} from 'gatsby';
import Button from './Button';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';

const BannerRussel = ({title, description}) => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/bg-section-header.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const bgImage = convertToBgImage(getImage(placeholderImage));

  return (
    <section className="py-28">
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <div className="relative flex flex-col items-center justify-center w-full pt-12 pb-8 mb-8 text-white md:flex-row lg:mx-auto filter drop-shadow-2xl">
          <div className="relative flex flex-col-reverse items-center justify-center mt-4 md:ml-8 md:mt-0">
            <div>
              <h3 className="pb-4 text-3xl font-bold text-center md:text-2xl">{title}</h3>
              <div className="px-4 text-center pb-7 text-md md:text-lg">{description}</div>
              <div className="w-full px-4 mx-auto text-center">
                <Link to="/demo">
                  <Button label="Schedule Demo" variant="secondary" />
                </Link>
              </div>
            </div>
            <div className="relative flex flex-col justify-between h-full md:absolute md:top-20 md:-left-12">
              <img
                src={russelThinking}
                alt="Russell Trustle thinking"
                className="max-w-[80px] md:max-w-[110px] lg:max-w-[170px]"
              />
            </div>
          </div>
        </div>
      </BackgroundImage>
    </section>
  );
};
export default BannerRussel;
