import React from 'react';

const Button = ({label, variant = 'primary'}) => {
  const buttonClasses = `transition duration-300 ease-in-out text-white text-center rounded-3xl hover:drop-shadow-lg md:min-w-max md:max-w-[180px] py-2 w-full lg:text-lg z-10 px-2 mb-1 md:py1 mx-auto  font-bold`;

  const hoverPrimary =
    'bg-gradient-to-r from-[#0091FF] via-[#005799] to-[#002761] hover:from-[#4185F4] hover:via-[#00315E] hover:to-[#00315E]';

  const getColorClasses = (variant) => {
    switch (variant) {
      case 'primary':
        return hoverPrimary;

      case 'secondary':
        return 'border border-white hover:border-[#0067C4] hover:bg-[#F6F8FE] hover:text-[#0067C4] active:bg-#F6F8FE]';
      default:
        return '';
    }
  };

  const variantClasses = getColorClasses(variant);

  return <div className={`${buttonClasses} ${variantClasses}`}>{label}</div>;
};
export default Button;
