import React from 'react';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

const BackGlossary = () => {
  return (
    <Link to="/learn/glossary">
      <div className="flex items-center">
        <StaticImage src="../images/arrow-left.svg" className="w-4 md:w-8" alt="arrow back" />
        <div className="pl-4 font-bold md:pl-8 text-blue">Back to Glossary</div>
      </div>
    </Link>
  );
};

export default BackGlossary;
